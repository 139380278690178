.text{
    @apply font-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: -0.33px;

    color: #032D23;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
