.fees_input{
    width: 100px;
}

.fees_input input, .changePrice input{
    padding: .5rem !important;
    margin-left: 5px;
    font-size: 15px;
}
.fees_input span, .changePrice span{
    font-size: 15px !important;
}


.filter{
    width: 1000px;
}

.changePrice{
    padding-right: 20px;
}
