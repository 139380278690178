

.container{
    --x-padding: 10px;
    --y-padding: 20px;
    width: 100%;
    position: relative;
}
.table{
    /*
    @apply w-full lg:w-10/12 2xl:w-8/12;
    */
    width: 100%;
    margin: 10px auto 0 auto;
}

.thead{
    /*
    background: var(--color-primary);
    */
    color: black;
    padding: 5px var(--x-padding);
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 5rem;
    z-index: 1;
    background: white;
}

.thead .td{
    flex-wrap: nowrap;
    display: flex;
}


.th .td:hover > svg.sortDefaultIcon{
    opacity: 1;
}

.th, .tr, .table_titles_container, .tdContainer{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-items: left;
    justify-content: space-between;
    transition: background .3s;
}

.actionsContainer{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.th .td, .table_title{
    @apply text-sm lg:text-xl;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: sans-serif;
    cursor: pointer;
}

.tbody{
    /*
    box-shadow: 0 0 5px #ccc;
    */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.tbody .tr{
    padding: 15px var(--x-padding) 15px var(--x-padding);
}

.tbody .tr:nth-child(2n){
    background: var(--color-white-smoke);
}

.tbody .tr:last-child{
    /*    border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;*/
    border-bottom: 0;

    padding: 15px 10px 15px 10px;

}

.tbody .td{
    @apply lg:text-xl text-base;
    padding-left: 10px;
}
.table_editable .td{
    padding-left: 0;
}

.td{
    @apply mr-0.5;
    align-self: center;
}

.checkbox{
    position: relative;
    bottom: 6px;
    margin-right: 1rem;
    top: 1px;
}

.tr_checked{
    background: #d9d9d9 !important;
}


.dataNotFound{
    @apply p-8;
}

.tr:hover > .editContainer, .tr:hover > .viewContainer{
    /*
    opacity: .6;
    */
}
.editContainer, .viewContainer{
    position: relative;
    opacity: 1;
    transition: opacity .3s;
    cursor: pointer;
}
.viewContainer{
    margin-right: 10px;
}
.editContainer:hover, .viewContainer:hover{
    opacity: 1 !important;
}

.td_space{
    width: 20px;
}


.title, .table_title{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.7rem;
    padding: 0 20px 5px 20px;
    letter-spacing: .1rem;
}

.table_titles_container{
    padding: 0 var(--x-padding);
    margin: 5px 0;
    border-bottom: 1px solid #ccc;
}

.table_title{
    font-size: 1.8rem;
    cursor: default;
    border-right: 1px solid #ccc;
    text-align: center;
}
.table_title:last-child{
    border-right:0;
}

.title_space{
    width: 40px;
    margin-right:1rem;
}

.group_tr{
    width:100%;
    text-align: center;
    position: relative;
    height: 40px;
    border-bottom: 1px solid #ccc;
    background: #F6F7FB;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}
.group_tr div{
    left: 50%;
    padding: 5px 0;
    font-size: 20px;

}


.notes{
    max-height: 50px;
    overflow-y: hidden;
    max-width: 200px;
}
.popup{
    position: fixed;
    max-width: 700px;
    max-height: 700px;
    padding: 20px;
    background: #fff;
    color: #000;
    font-size: 1.7rem;
    top: 100px;
    right: 50%;
    transform: translate(50%,0);
    z-index: 999999;
    border: 2px solid #ccc;
    border-radius: 14px;
    overflow-y: scroll;
}

