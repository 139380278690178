.middle{
    display: inline-block;
    @apply font-sans;
    font-weight: 400;
    font-size: 2rem;
    line-height: 30px;
    text-align: center;
}

@media (min-width: 768px){
    .middle {
        font-size: 2.4rem;
    }
}
