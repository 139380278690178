.container{
    position: relative;
    background: white;
    border-radius: 100%;
}

.info{
    position: absolute;
    width: 220px;
    padding: 20px;
    font-size: 1.2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background: var(--color-primary);
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 8px;
    box-shadow: 0 0 5px #666;
    bottom: 50px;
}
