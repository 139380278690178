.parent{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    justify-items: center;
    visibility: hidden;
    background: rgba(0,0,0,0);
    transition: all .5s;
}

.moreIndex{
    z-index: 9999;
}

.parentVisible{
    visibility: visible;
    background: rgba(0,0,0,.3);
}


.modal{
    z-index: 999;
    padding: 20px;
    background: #fff;
    border-radius: 100px;
    box-shadow: 0 0 5px #3b3b3b;
    left: 0;
    right: 0;
    top: 2%;
    margin: 0 auto;
    position: fixed;
    transform: scale(0);
    opacity: 0;
    transition: all .2s ease;
    visibility: hidden;
    max-height: 90%;
    overflow-y: auto;
}

/*
@media (min-height: 1100px) {
    .modal{
        max-height: 1000px;
    }
}
*/

.visible{
    border-radius: 4px;
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}
.closeButton{
    position: fixed;
    top: 10px;
    font-size: 18px;
    right: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 2;
    cursor: pointer;
}
.closeButton:hover{
    box-shadow: 0 0 3px #ddd;
}
