
.remove_button{
    width: 300px;
    @apply md:ml-10 relative;
    top: 40px;
}
.remove_button button{
    position: absolute;
    bottom: 0;
    font-size: 1.5rem;
    padding: 11px;
}


.add_button{
    width: 200px;
}
.add_button button{
    padding: 8px !important;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

@media only screen and (max-device-width: 641px){
    .remove_button{
        width: 200px;
        height: 48px;
    }
    .add_button{
        @apply mt-5;
    }
}
