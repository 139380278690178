.modal{
    width: 700px;
    padding-right: 80px;
    padding-top: 20px;
}

.delete_group{
    position: absolute;
    top: -10px;
    right: 30px;
}
.title{
    width:100%;
    padding: 20px;
    font-size: 20px;
    border-top: 1px solid #ccc;
    margin-bottom:10px;
    display:flex;
    line-height: 15px;
}
.title:hover{
    background: #F5F5F5FF;
}
.title svg, .svg_container svg{
    opacity:.5;
    /*visibility:hidden;*/
    transition:all .2s;
}
.title:hover svg, .svg_container:hover svg{
    opacity:1;
    visibility:visible;
}
.title svg:hover, .svg_container svg:hover{
    cursor: pointer;
    transform: scale(1.5);
}

@media (max-width: 900px) {
    .table_title {
        font-size: 1.8rem !important;
    }
}


.tableTd{
    font-size: 18px;
}

.editSVG{
    position: relative;
    top: 5px;
}
