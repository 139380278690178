.simplified_data{
    position: absolute;
    display: none;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ddd;
    font-size: 1.5rem;
    padding: 4px;
    color: #666;
    z-index: 2;
}

.simplified_data_container{
    position: relative;
}
.simplified_data_container:hover > .simplified_data{
    display: block;
}

.simplified_data table{
    text-align: left;

}
.simplified_data  td{
    padding-right: 2px;
}
.simplified_data th, .simplified_data td{
    border: 1px solid #ccc;
}
