.starOff:active, .starOn:active{
    transform: scale(1.5);
}
.starOn, .starOff{
    transition: color .5s;
}

.starOn path{
    fill: #FFCE31;
}

.starOff path{
    fill: #ccc;
}
.starOff:hover > path{
    fill: #FFCE31;
}
