.buttons{
    width: 500px;
    display: flex;
}

.button{
    width: 200px;
    height: 52px;
    position: relative;
    margin-top: 2.5rem;
}

.removeButton{
    font-size: 1.5rem;
    padding: 8px;
    width: auto;
}

.addOwnerButton{
    @apply mt-10;
    font-size: 1.6rem;
    padding: 8px 20px;
    width: auto;
}

.container{
    margin-top: 20px;
}


.container label{
    margin-right: 10px;
    line-height: 1.5rem !important;
    @apply font-sans text-2xl mb-5 block;
}
.container input{
    margin-right: 10px;
}


