.cadastre{
    width: 100%;
    margin: 0 10px;
}
.title{
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 20px;
    background: var(--color-primary);
}
.content{
    width: 100%;
    margin: 10px 0;
}
.element{
    display: flex;
    margin-left: 10px;
    margin-bottom: 5px;
}
.element div{
    font-size: 14px;
}
.element div:first-child{
    color: #666;
    width: 200px;
}
