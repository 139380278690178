.modal{
    width: 800px;
    position: relative;
}

.selectContainer{
    font-size: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3ms;
}
.selectContainer:hover{
    background: #F5F5F5;
}

.imagesNotFound{
    padding: 20px;
    font-size: 20px;
}

.images_container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.image_container{
    position: relative;
    margin: 10px;
}

.image{
    position: relative;
    cursor: pointer;
    transition: all .3ms;
    padding: 10px;
}

.image:hover{
    background: #F5F5F5;
}

.zoomSVGContainer{
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.zoom_image{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: #F5F5F5;
    cursor: zoom-out;
}

.selected_image{
    background: #2d88c4;
}
.selected_image:hover{
    background: #419fdc;
}
