.container{
    width: 100%;
    padding: 20px;
    position: relative;
    cursor: pointer;
    display: flex;
}
.container svg{
    fill: #1587EA;
}


.container:hover .change_account{
    display: flex;
}

.change_account{
    display:none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #5f5f5f;
    opacity:.5;
    color: white;
    font-size: 22px;
    font-weight:bold;
    justify-content: center;
    align-items: center;
}
