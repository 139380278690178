.buttons{
    width: 800px;
    display: flex;
    height: 52px;
    position: relative;
    top: 28px;
}

.finalButtons{
    width: 250px;
}
