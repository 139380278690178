.container{
    width: 400px;
    display: flex;
    flex-wrap: wrap;
}
.tr{
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.4rem;
}
.th, .title{
    font-weight: bold;
    border-right: 1px solid #ccc;
    min-width: 200px;
    text-transform: capitalize;
}
.td{
    overflow-y: auto;
}

.th, .td, .title{
    padding: 5px 5px;
}

.title{
    width: 100%;
    text-align: center;
}
.tr:hover{
    background: whitesmoke;
}
