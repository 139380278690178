.container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: 400px;
}

.fileContainer{
    @apply relative p-2 text-center flex justify-center items-center flex-wrap;
    background: #fff;
    width: 160px;
    height: 160px;
    border: 1px solid transparent;
    border-radius: 4px;
    animation: showFileContainer ease forwards .5s;
    transform: scale(0);
    box-shadow: 0 0 3px #ccc;
    transition: border-radius 1s, width .5s;
    overflow: hidden;
}
.draggingFileContainer{
    border-radius: 100%;
    box-shadow: 0 0 15px #ccc;
}

.fileName{
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 auto;
    width: 100%;
    font-size: 1.1rem;
}

.selectedFileContainer{
    border: 1px solid #f3b348 !important;
    box-shadow: 0 0 10px #f3b348;
}

.dragAllowedFileContainer{
    background: #31d0af;
}

.highLightFileContainer{
    background: #93c3eb;
}

.disabled{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 1;
}
.disabled svg{
    width: 160px;
    height: 160px;
}

@keyframes showFileContainer {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}



.menu{
    @apply flex flex-nowrap items-center;
    padding-left: 10px;
    min-height: 60px;
    width: 100%;
}
.menu a{
    @apply ml-2;
    font-size: 18px;
}

.iconMenu{
    @apply mr-5 cursor-pointer;
    transition: transform .5s;
}
.iconMenuContainer:hover svg, .iconMenu:hover{
    transform: scale(1.3);
}

.iconMenuContainer{
    @apply flex flex-nowrap items-center cursor-pointer hover:shadow p-2;
}
.iconMenuContainer svg{
    transition: transform .5s;
}

.checked{
    position: absolute;
    top: 10px;
    right: 5px;
    background: #fff;
    box-shadow: 0 0 3px #ccc;
    padding: 5px;
    border-radius: 100%;
}

.menuButtonContainer{
    @apply flex flex-nowrap items-center cursor-pointer p-2 mr-5;
    border-radius: 3px;
    font-size: 1.5rem;
    border: 2px solid #ccc;
    transition: all .5s;
}
.menuButtonContainer:hover{
    border: 2px solid var(--color-primary);
}
.menuButtonContainer svg{
    @apply mr-5 cursor-pointer;
    transition: fill .5s;
    width: 24px;
    height: 24px;
}
.menuButtonContainer:hover svg, .menuButtonContainer svg:hover{
    fill: var(--color-primary);
}
