.container{
    padding: 20px;
}
.modal{
    width: 500px;
}




.enterprise{
    margin: 5px 0;
    cursor: pointer;
    box-shadow: 0 0 5px #ccc;
    padding: 10px;
    border-left: 6px solid var(--color-primary);
    border-radius: 3px;
    transition: all .3s;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}

.enterprise:hover{
    transform: scale(1.05);
}
.small_text{
    color: #666 !important;
}


.enterprises{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: visible;
    width: 460px;
    position: relative;
    left: -21px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;
}

/* phone and tablets */
@media only screen and (max-device-width: 641px){
    .container{
        padding: 0;
        height: 100%;
    }
    .modal{
        width: 100%;
        height: 100%;
    }
    .enterprises{
        width: 100%;
        max-height: initial;
        overflow-y: scroll;
        left: 0;
    }
}
