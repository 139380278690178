.container{
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    bottom: -5px;
}
.container svg{
    position: relative;
}

.expanded{
    position: absolute;
    bottom: -80px;
    background: #fff;
    left: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.option{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

}

.option:hover{
    background: #ccc;
}

.option a{
}
.option svg{
    width: 24px;
    height: 24px;
}
