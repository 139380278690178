.checkbox{
    display: flex;
}
.checkbox label{
    margin-right: 10px;
    line-height: 1.5rem !important;
    @apply font-sans text-2xl mb-5 block;
}
.checkbox input{
    margin-right: 10px;
}


.remove_button{
    width: 300px;
    @apply md:ml-10 mt-5 md:mt-0 relative;
}
.remove_button button{
    position: absolute;
    bottom: 0;
    font-size: 1.5rem;
    padding: 11px;
}



.add_button{
    width: 200px;
}
.add_button button{
    padding: 8px !important;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

@media only screen and (max-device-width: 641px){
    .remove_button{
        width: 200px;
        height: 48px;
    }
    .add_button{
        @apply mt-5;
    }
}
