.accordion{
    display: flex;
    flex-direction: column;
    width: 100%;

}
.container{
    width: 100%;
    position: relative;
}
.title{
    @apply font-sans text-2xl my-5 block;
    position: relative;
    display: block;
    z-index: 1;
    cursor: pointer;
}
.title span{
    background: white;
    user-select: none;
    left: 20px;
    position: relative;
    padding-right: 10px;
}


.icon{
    display: inline-block;
    left: 20px;
    position: relative;
    padding: 0 10px;
    background: white;
}
.icon svg{
    transition: transform .3s;
}

.iconVisible svg{
    transform: rotate(180deg);
}

.header{
    background: transparent;
    padding: 15px 0;
    box-shadow:  1px 1px 3px #ccc, 1px -1px 3px #ccc;
    transition: box-shadow .5s;
}
.header:hover{
    box-shadow:  1px 1px 4px #ccc, 1px -1px 4px #ccc;
}
.header:active{
    box-shadow: inset 1px 1px 3px #ccc, 1px -1px 3px #ccc;
}
.header span{
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.header .icon, .header span{
    background: none;
}

.header .icon{
    padding-left: 0;
}


.bar_container{
    position: relative;
    width: 100%;
    top: -30px;
}
.bar{
    width: 100%;
    height: 1px;
    background: #ccc;
    position: absolute;
    top: 10px;
    z-index: 0;
}

.content{
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .3s;
    overflow-y: hidden;
    padding: 0 10px;
    padding-bottom: 10px;
}

.visibleContent{
    opacity: 1;
    max-height: 10000px;
    visibility: visible;
    overflow-y: visible;
}
