.container{
    position: fixed;
    z-index:99;
    background: #fff;
    border-right: 1px solid var(--color-grey);
    width: 200px;
    height: 100%;
    /*background: linear-gradient(to bottom, #4286f4, #373B44); */
}

.smallContainer{
    height: 100px;
    overflow-x: hidden;
}


/* phone and tablets */
@media only screen and (max-device-width: 641px){
    .container{
        display: none;
    }
}


.menu{
    margin-top: 1rem;
    position: relative;
}
.menuText{
    margin: 2rem 0 0 0;
    text-transform: uppercase;
    color: #3b3b3d;
    padding-left: 20px;
    font-size: 1.8rem;
    border-bottom: 1px solid #3b3b3d;
}

.element{
    width: 100%;
    text-align: left;
    line-height: 24px;
    padding: 5px 0 5px 10px;
    margin-bottom: .5rem;
    transition: all .3s;
    background: #fff;
    border: 1px solid transparent;
    font-family: sans-serif;

}
.element:hover{
    /*
    background: #f3af40;
    */
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.element:hover > span{
}
.element span{
    cursor: pointer;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: bold;
    color: #666;
    letter-spacing: 1px;
}

@media (min-height: 700px){
    .element span{
        /*font-size: 1.6rem;*/
    }
    .element{
        /*padding: 15px 0 15px 10px;*/
    }
}

.selected{
    /*
    box-shadow: 1px 0 2px var(--color-terciary);
    */
    width: 100%;
    border-left: 4px solid var(--color-primary);
    /*
    background: linear-gradient(to right, var(--color-primary), var(--color-terciary));
    */
}
.selected:hover{
    /*
    background: linear-gradient(to right, var(--color-primary), var(--color-terciary));
    */
}
.selected span{
    color: var(--color-primary);
}
