.mapContainer{
    height: 500px;
    width: 800px;
    position: relative;
}

.container label{
    margin-right: 10px;
    line-height: 1.5rem !important;
    @apply font-sans text-2xl mb-5 block;
}
.container input{
    margin-right: 10px;
}

.provinces{
    align-items: center;
}

.provinces table{
    display: block;
    width: 200px;
    height: 500px;
    overflow-y: scroll;
    text-align: left;
    margin-left: 20px;
}


.mapShowContainer{
    position: absolute;
    right: 3px;
    z-index: 2;
    bottom: 2px;
}

/* phone and tablets */
@media only screen and (max-device-width: 641px){
    .provinces table{
        display: table;
        width: 100%;
        margin-left: 0;
    }
    .provinces{
        @apply mt-10;
        width: 100%;
    }
}
