.table{
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.4rem;
}
.table td{
    padding: 5px;
    border: 1px solid #ccc;
}
.title{
    font-weight: bold;
}
