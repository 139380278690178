.button{
    width: 200px;
}
.buttons{
    width: 500px;
    display: flex;
}
.generate_catastre_checkbox{
    @apply my-10;
}
.generate_catastre_checkbox input{
    margin-right: 10px;
}
.generate_catastre_checkbox label{
    font-size: 18px;
}
