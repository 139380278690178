.parent {
    @apply flex justify-center items-center h-full w-full;
    background-image: url("../../resources/images/login_bg.jpg");
    background-size: cover;
}

.container {
    border-radius: 7px;
    padding: 20px;
    padding-top: 50px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 500px;
    box-shadow: 1px 0 50px #2b2b2b;
}

.container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 7px;
    opacity: .7;
    background: black;
}

.input {
    margin-top: 2rem;
}
.input > div{
    background: rgba(0,0,0,0.3) !important;
    border-bottom: 1px solid #ccc;
    border-radius: 0;

}

.input input{
    color: white !important;
    font-size: 1.7rem;
    margin-left: 15px;
}

.logo {
    position: absolute;
    top: -120px;
    opacity: 1;
    background: white;
    z-index: 1;
    border-radius: 2px;
    left: calc(50% - 100px);
    box-shadow: 1px 0 50px #2b2b2b;
}


.loginTitle{
    color: #dbdbdb;
    font-size: 3rem;
    text-align: center;
    font-family: sans-serif !important;
    margin-bottom: 3rem;
}

.errorText{
    margin-top: 2rem;
    font-family: sans-serif;
}
