.text{
    @apply font-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.33px;

   /* color: #032D23;*/
    color: #3b3b3b;
}
