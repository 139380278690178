.list{
    width: 100%;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: all-scroll;
    font-size: 1.6rem;
    margin-bottom: 10px;
    touch-action: none;
    transition: background-color, border .5s ease;
}

.list:hover{
    background: #f4f4f4;
    border: 1px solid #ccc;
}
