.container{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid var(--color-grey);
}

.profile{
    position: fixed;
    top: 10px;
    right: 10px;
    font-family: sans-serif;
    font-size: 1.8rem;
    color: #3b3b3b;
    padding: 0 10px;
    z-index: 20;
}


.menu{
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 200px;
    right: 0;
    padding: 5px;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: max-height .5s;
}
.menu:after,
.menu:before{
    content: "";
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    right: 3px;
    top: -15px;
}
.menu:before{
    content: "";
    border-color: transparent transparent #ccc transparent;
    border-width: 16px;
    top: -32px;
    right: 2px;
}


.showMenu{
    max-height: 200px;
    overflow: visible;
    visibility: visible;
}

.element{
    cursor: pointer;
    padding: 10px;
}

.element:hover{
    background: var(--color-white-smoke);
}

.name{
    max-width: 24rem;
    cursor: pointer;
    text-align: right;
    height: 30px;
    overflow: hidden;
    display: inline-block;
}
.name span{
    max-width: 20rem;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.name:hover{
    text-decoration: underline;
}

.notificationContainer{
    display: inline-block;
    position: relative;
    bottom: 8px;
    margin-right: 12px;
    cursor: pointer;
}

.bell_number{
    position: absolute;
    color: white;
    top: 0;
    left: 5px;
    font-size: 12px;
}

.modal{
    width: 800px;
    min-height: 300px;
}
