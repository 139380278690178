.container{
    position: relative;
    display: inline-block;
    margin-left: 1rem;
    cursor: pointer;
}
.container svg{
    position: relative;
    bottom: 5px;
}
.children{
    font-size: 18px;
    color: #3b3b3b;
    display: flex;
    flex-direction: column;
}
.children a{
    margin-left: 1rem;
}