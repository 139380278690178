.remove_button{
    width: 300px;
    @apply ml-10 relative;
}
.remove_button button{
    position: absolute;
    bottom: 0;
    font-size: 1.5rem;
    padding: 11px;
}

.add_button{
    width: 200px;
}
.add_button button{
    padding: 8px !important;
    margin-bottom: 10px;
    font-size: 1.5rem;
}
