

label.radio{
    display: inline-block;
    cursor: pointer;
    --radioSize: 22px;
}
label.radio input{
    display: none;
}
label.radio span{
    display: block;
    position: relative;
    line-height: var(--radioSize);
    height: var(--radioSize);
    padding-left: var(--radioSize);
}
label.radio span:not(:empty){
    padding-left: calc(var(--radioSize) + 8px);
}
label.radio span::before, label.radio span::after{
    content: '';
    width: var(--radioSize);
    height: var(--radioSize);
    display: block;
    border-radius: 50%;
    left: 0;
    top: 0;
    position: absolute;
}
label.radio span::before{
    background: transparent;
    border: 2px solid var(--color-primary);
    transition: background .3s ease, transform .3s cubic-bezier(.175, .885, .32, 2);
}
label.radio span::after {
    background: var(--color-primary);
    transform: scale(0);
    transition: transform .3s cubic-bezier(.175, .885, .32, 1.4);
}


/*label.radio input:checked + span::before{
    transform: scale(.78);
    background: var(--color-primary);
}*/
label.radio input:checked + span::after {
    transform: scale(.6);
    transition: transform .3s ease;
}

label.radio span:hover span::before{
    transform: scale(.92);
}
label.radio input span::after {
    transform: scale(.74);
}
/*label.radio:hover > input:checked span::after {
    transform: scale(.4);
}*/
