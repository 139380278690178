.table{
    width: 100%;
    position: relative;
    margin: 20px 0;
}
.thead{
    color: black;
    padding: 20px var(--x-padding) !important;
    border-bottom: 1px solid #ccc;
    z-index: 1;
    background: whitesmoke;
    margin-bottom: 5px;
}
.thead .th{
    padding: 10px 0;
}
.th, .tr{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-items: left;
    justify-content: space-between;
    transition: background .3s;
    margin-bottom: 5px;
}

.thead .th input{
    background: whitesmoke;
}

.table input{
    font-size: 14px;
    border: 1px solid transparent;
    width: 100%;
    padding: 5px 5px;
    outline: none;
}
.th input:hover, .td input:hover{
    border: 1px solid #ccc;
}
.th input{
    font-weight: bold;
}

.td{
    font-size: 13px;
}
.table svg{
    position: relative;
    top: 10px;
    cursor: pointer;
    margin: 0 10px;
}


