.button, .button2, .button3, .button4, .button5{
    font-family: sans-serif;
    border-radius: 3px;
    color: white;
    padding: 13px;
    border: none;
    outline: none;
    appearance: none;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 24px;
    box-shadow: 0 0 40px 40px #3498db inset, 0 0 0 0 #3498db;
    /* identical to box height, or 120% */

    text-align: center;
    letter-spacing: -0.33px;
    width: 100%;
    cursor: pointer;
    transition: all .3s ease;
}
.button:hover{
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    background: #2C3E50;
    transform: scale(0.9);
}

.button2:hover, .button3:hover, .button4:hover, .button5:hover{
    transform: scale(0.98);
}

.button2:hover{
    box-shadow: 0 0 40px 40px #2d88c4 inset, 0 0 0 0 #2e89c4;
}

.button3, .button5{
    box-shadow: 0 0 40px 40px var(--color-primary) inset, 0 0 0 0 var(--color-terciary);
}
.button3:hover, .button5:hover{
    box-shadow: 0 0 40px 40px #cf9536 inset, 0 0 0 0 #b57d19;
    transform: scale(0.98);
}
.button4{
    /*
    box-shadow: 0 0 40px 40px #e63a3a inset, 0 0 0 0 #cc2929
    */
    box-shadow: 0 0 40px 40px #ffffff inset, 0 0 0 0 #cc2929;
    color: #666;
    border: 1px solid #ccc;
}
.button4:hover{
    /*
    box-shadow: 0 0 40px 40px #d45252 inset, 0 0 0 0 #cf2929;
    */
}
.loading{
    background: white;
}

@media(min-width: 1024px){
    .button{
        padding: 15px;
    }
}

.button5{
    font-size: 1.5rem;
    padding: 10px;
}
