.container{
    width: 100%;
}
.container textarea{
    @apply font-sans text-xl p-4;
    width: 100%;
    border-bottom: 2px solid #DADADA;
    box-shadow: 0 0 2px #ccc;
    outline: none;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.33px;
    color: var(--color-black);
    background: #fff;
    transition: background, box-shadow .2s;
    border-radius: 6px;
}

.container:hover > textarea{
    box-shadow: 0 0 5px #ccc;
    background: rgba(73, 133, 224, 0.12);
}

.container textarea::placeholder{
    @apply text-gray-500;
}


.container label{
    @apply font-sans text-2xl mb-5 block;
    font-weight: bold;
}
