.container{
    position: relative;
    width: 100%;
    background: var(--color-white);
    z-index: 5;
    border-radius: 6px;
    background: white;
    box-shadow: 0 0 2px #ccc;
    transition: all .2s;
    display: flex;
}
.container:hover{
    box-shadow: 0 0 5px #ccc;
    background: rgba(73, 133, 224, 0.12);
}
.container input{
    @apply font-sans text-xl p-4;
    width: 100%;
    border: none;
    outline: none;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.33px;
    color: var(--color-black);
    background: transparent;
    display: inline-block;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.container label{
    font-size: 16px;
    cursor: text;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, -5px) scale(1);
    transition: all .1s ease-in-out;
    padding-left: 16px;
    left: 0;
}

.container .icon + input{
    padding-left: 29px;
}
.container .icon ~ label{
    padding-left: 45px;
}

.container input.active + label {
    transform: translate(4px, -16px) scale(.75);
    color: rgb(112,112,112);
}

.container .icon + input.active + label{
    transform: translate(12px, -16px) scale(.75);
}

.container .icon{
    position: relative;
    left: 10px;
    top: 10px;
}


.container .data{
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: var(--color-white);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    overflow: hidden;
    max-height: 0;
    transition: max-height .2s ease-in;
    overflow-y: auto;
}

.container .dataElement{
    width: 100%;
    padding: 8px 16px;
    background-color: var(--color-white);
    transition: background-color .2s ease;
    font-size: 16px;
    cursor: pointer;
    text-align: left;
}
.container .dataElement:first-child{
    border-top: 1px solid #ccc;
}
.container .dataElement:hover{
    background-color: #F1FFFC;
}

.dataIcon{
    display: inline-block;
    margin-right: 10px;
}

.dataText, .dataSmallText{
    @apply font-sans;
    text-align: left;
}
.dataText{
    font-size: 16px;
    font-weight: bold;
}
.dataSmallText{
    font-size: 14px;
    color: rgb(112,112,112);
}

.dataActive{
    max-height: 200px !important;
    transition: max-height .2s ease;
    border: 1px solid #ccc;
}
