.content{
    margin-left: 200px;
}
/* phone and tablets */
/*@media only screen and (max-device-width: 641px){
    .content{
        margin-left: 0;
    }
}*/
@media (max-device-width: 641px){
    .content{
        margin-left: 0;
    }
}

.showMenu{
    position: relative;
    z-index: 9999;
    font-size: 1.4rem;
}
