.dragIcon{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.buttons{
    width: 500px;
    display: flex;
}

.button{
    width: 250px;
    height: 52px;
    position: relative;
    margin-left: 2.5rem;
}
