@import url("css/radioInput.css");
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-white: #fff;
    --color-black: #000;
    --color-grey: #ccc;
    --color-white-smoke: #F5F5F5;
    --color-light-grey: #d9d9d9;

    --color-primary: #f3af40;
    --color-secondary: #4286f4;
    --color-terciary: #4519cd;
    --color-terciary2: #eca62b;
}

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Animations */

@keyframes show {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

:root {
    --default-animation-show: show .5s ease-in forwards;
}


html,body, .App, #root{
    height: 100%;
}


.menuHeader{
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;
    padding: 10px;
    position: fixed;
    background: white;
    top: 0;
    z-index: 10;
}

.menuHeader a{
    font-size: 18px;
    color: #3b3b3b;
    padding: 0 10px;
    border-right: 1px solid #ccc;
}
.menuHeader a:hover{
    text-decoration: underline;
}
.menuHeader a:last-child{
    border-right: 0;
}
.menuHeader > div{
    border-right: 1px solid #ccc;
}
.menuHeader > div:last-child{
    border-right: 0;
}

.menuHeaderFullPage{
    width: 100%;
    background: #fff;
}
.menuHeaderFullPage a{
    font-size: 18px;
    color: #3b3b3b;
    padding: 0 10px;
}


a[href^="mailto"]:hover, a[href^="tel:"]:hover{
    text-decoration: underline;
    color: #2d88c4 !important;
}

a[href^="mailto"], a[href^="tel:"]{
    color: #2d88c4 !important;
}
label{
    font-size: 14px;
    line-height: 14px;
}

.label{
    @apply font-sans text-2xl mb-5 block;
    font-weight: bold;
}


.inputIconText{
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.map-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}


.filter{
    width: 800px;
}

.showDataContainer{
    padding-top: 50px;
    width: 100%;
}
.mapContainer{
    height: 500px;
    width: 800px;
    position: relative;
    margin: 0 auto 30px auto;
}

.editContainer{
    @apply pr-5 pb-10;
}
.showContainer{
    padding-bottom: 300px;
}

.show_separated{
    @apply p-5 font-sans text-xl text-justify;
}

.link_color{
    cursor: pointer;
    color: #2d88c4 !important;
}

.starOn path{
    fill: #FFCE31;
}
.starOff path{
    fill: #ccc;
}

pre{
    white-space: pre-wrap;
}

@media (max-width: 1280px){
    .menuHeader a{
        font-size: 14px;
    }
}


/* phone and tablets */
@media only screen and (max-device-width: 641px){
    .menuHeader a{
        font-size: 14px;
    }
    .filter{
        width: 100%;
    }
}
