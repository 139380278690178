.container{
    border-radius: 6px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    background: white;
    box-shadow: 0 0 2px #ccc;
    transition: all .2s;
    position: relative;
}
.container:hover{
    box-shadow: 0 0 5px #ccc;
    background: rgba(73, 133, 224, 0.12);
}

.container input{
    @apply font-sans text-xl p-4;
    width: 100%;
    border: none;
    outline: none;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.33px;
    color: var(--color-black);
    background: transparent;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.container input::placeholder{
    @apply text-gray-500;
}

.icon{
    position: relative;
    left: -10px;
    display: flex;
    align-items: center;
    user-select: none;
}
.icon:first-child{
    left: 10px;
    margin-right: 5px;
}


.label{
    @apply font-sans text-2xl mb-5 block;
    font-weight: bold;
}

.maskLayer{
    @apply font-sans text-xl p-4;
    position: absolute;
    top: 5px;
    left: 0;
    width: 80%;
    z-index: 1;
    font-weight: bold;
}
