
.container{
    overflow-y: hidden;
}

.container input[type="file"]{
    display: none;
}
.input_label{
    display: flex;
    width: 180px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 12px 18px;
    cursor: pointer;
    box-shadow: 0 0 0 #ccc;
    transition: all .5s;
    line-height: 18px;
}
.input_label:hover{
    box-shadow: 0 0 3px #ccc;
}


.r_container{
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px #ccc;
    padding: 2rem;
}

.resource{
    padding: 2rem;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: background-color .3s;
    border-radius: 10px;
}
.resource:hover{
    background-color: #DADADA;
}
.resource svg{
    display: block;
}


.resource_icons{
    @apply flex;
}
.resource_icons svg{
    @apply cursor-pointer transition mr-5;
}
.resource_icons svg:hover{
    transform: scale(1.5);
}

.r_name{
    text-align: left;
    font-size: 16px;
    line-height: 50px;
    text-overflow: ellipsis;
    width: 450px;
}
@media (min-width: 900px){
    .r_name{
        width: 100%;
    }
}


.loading_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 90%;
    z-index: 99;
}

.loading_container img{
    width: 80px;
    height: 80px;
}
