.container{
    display: flex;
}
.container input[type="checkbox"]{
    position: relative;
    width: 2.8rem;
    height: 2.8rem;
    border: 1px solid var(--color-grey);
    border-radius: 3px;
    appearance: none;
    outline: none;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1,0.1,0.25,1);
}
.container input[type="checkbox"]::before{
    content: '';
    position: absolute;
    display: block;
    top: 3px;
    left: 8px;
    width: 1rem;
    height: 1.5rem;
    border-style: solid;
    border-color: var(--color-white);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
}

.container input[type="checkbox"]:checked {
    color: var(--color-white);
    border-color: var(--color-primary);
    background: var(--color-primary);
}

.container input[type="checkbox"]:indeterminate{
    appearance: auto;
    -webkit-appearance: checkbox;
}

.container input[type="checkbox"]:checked::before{
    opacity: 1;
}


.container label{
    line-height: 1.5rem !important;
    display: block;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    position: relative;
    top: 5px;
}

.small input{
    transform: scale(0.6);
}
